import { ANIMATION_DURATION } from '@lanyan/constant'
import { Blur } from 'konva/lib/filters/Blur'
import { debounce, isEqual, isNil, omit } from 'lodash-es'

import { EGraphicEvent, EGraphicType } from '../config'
import { IKonvaImageConfig, KonvaImage } from '../konva/Image'
import { IPos, ISize } from '../type'
import {
  formatCornerRadiusToArray,
  roundTo,
  roundTo1,
  roundToInt,
} from '../util'
import { GraphicMedia, IGraphicMediaConfig } from './Media'

export type IGraphicImageConfig = IGraphicMediaConfig &
  IKonvaImageConfig & {
    imageWidth?: number
    imageHeight?: number
    imageX?: number
    imageY?: number
  }

export class GraphicImage extends GraphicMedia<KonvaImage> {
  graphicType = EGraphicType.IMAGE

  imageWidth = 0

  imageHeight = 0

  imageX = 0

  imageY = 0

  _cropX = 0

  _cropY = 0

  _cropWidth = 0

  _cropHeight = 0

  cachedBlurRadius?: number

  restoreBlurRadius = debounce(() => {
    if (this.cachedBlurRadius) {
      this.blurRadius(this.cachedBlurRadius)
      this.cachedBlurRadius = undefined
    }
  }, ANIMATION_DURATION)

  preCropAttr?: {
    cropX: number
    cropY: number
    cropWidth: number
    cropHeight: number
    imageWidth: number
    imageHeight: number
    imageX: number
    imageY: number
  }

  constructor(graphicImageConfig: IGraphicImageConfig) {
    graphicImageConfig.width ||= 0
    graphicImageConfig.height ||= 0
    graphicImageConfig.x ||= 0
    graphicImageConfig.y ||= 0
    graphicImageConfig.imageX ||= 0
    graphicImageConfig.imageY ||= 0
    graphicImageConfig.imageWidth ||= graphicImageConfig.width
    graphicImageConfig.imageHeight ||= graphicImageConfig.height
    graphicImageConfig.cropX ||= 0
    graphicImageConfig.cropY ||= 0
    graphicImageConfig.cropWidth ||= graphicImageConfig.width
    graphicImageConfig.cropHeight ||= graphicImageConfig.height
    const {
      imageWidth,
      imageHeight,
      imageX,
      imageY,
      cropX,
      cropY,
      cropWidth,
      cropHeight,
    } = graphicImageConfig
    super({
      ...graphicImageConfig,
      graphic: new KonvaImage({
        ...omit(graphicImageConfig, [
          'cropX',
          'cropY',
          'cropWidth',
          'cropHeight',
        ]),
        x: imageX,
        y: imageY,
        width: imageWidth,
        height: imageHeight,
      }),
    })

    this.imageX = imageX
    this.imageY = imageY
    this.imageWidth = imageWidth
    this.imageHeight = imageHeight
    this._cropX = cropX
    this._cropY = cropY
    this._cropWidth = cropWidth
    this._cropHeight = cropHeight

    const imageRect = {
      imageX: 0,
      imageY: 0,
      imageWidth: 0,
      imageHeight: 0,
      cropWidth: 0,
      cropHeight: 0,
      cropX: 0,
      cropY: 0,
    }
    const croppedImageRect = {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    }

    // 处理拖拽中。
    this.on(EGraphicEvent.DRAG_MOVE, () => {
      const { x, y } = this.box.attrs
      this.graphic.position({
        x: Math.max(0, this.imageX) + x,
        y: Math.max(0, this.imageY) + y,
      })
    })

    // 处理开始缩放。
    this.on(EGraphicEvent.RESIZE_START, () => {
      if (!this.isCropping) {
        this.cacheBlurRadiusIfHas()
      }

      Object.assign(croppedImageRect, this.graphic.size())
      Object.assign(croppedImageRect, this.graphic.position())
      Object.assign(imageRect, {
        imageX: this.imageX,
        imageY: this.imageY,
        imageWidth: this.imageWidth,
        imageHeight: this.imageHeight,
        cropWidth: this._cropWidth,
        cropHeight: this._cropHeight,
        cropX: this._cropX,
        cropY: this._cropY,
      })
    })

    // 处理缩放。
    this.on(EGraphicEvent.RESIZE, () => {
      const { scaleX, x, y } = this.box.attrs
      this.imageX = imageRect.imageX * scaleX
      this.imageY = imageRect.imageY * scaleX
      this.imageWidth = imageRect.imageWidth * scaleX
      this.imageHeight = imageRect.imageHeight * scaleX
      this._cropWidth = imageRect.cropWidth * scaleX
      this._cropHeight = imageRect.cropHeight * scaleX
      this._cropX = imageRect.cropX * scaleX
      this._cropY = imageRect.cropY * scaleX

      this.graphic.position({
        x: Math.max(0, this.imageX) + x,
        y: Math.max(0, this.imageY) + y,
      })
      this.graphic.size({
        width: croppedImageRect.width * scaleX,
        height: croppedImageRect.height * scaleX,
      })
    })

    this.on(EGraphicEvent.RESIZE_END, () => {
      const { width, height } = this.calcActualRectBox
      this.box.setAttrs({
        width: this.graphicEditor!.actualSizeToLogicalSize(width),
        height: this.graphicEditor!.actualSizeToLogicalSize(height),
        scaleX: 1,
        scaleY: 1,
      })

      if (!this.isCropping) {
        this.restoreBlurRadius()
      }
    })

    if (this.hasCropped) {
      this.graphic.on(EGraphicEvent.LOAD_MEDIA_SUCCESS, () => {
        this.crop({
          cropX,
          cropY,
          cropWidth,
          cropHeight,
          imageX,
          imageY,
        })
      })
    }

    // 模糊半径在初始化时需要手动设置，因为设计到设置缓存和过滤器。
    if (this.blurRadius()) {
      this.graphic.visible(false)
      this.on(EGraphicEvent.FIRST_DRAW_COMPLETE, () => {
        this.blurRadius(this.blurRadius())

        this.graphic.visible(true)
      })
    }
  }

  // 是否发生了裁剪。
  get hasCropped() {
    return (
      roundToInt(this.imageX) !== 0 ||
      roundToInt(this.imageY) !== 0 ||
      roundToInt(this.width()) !== roundToInt(this.imageWidth) ||
      roundToInt(this.height()) !== roundToInt(this.imageHeight)
    )
  }

  // 获取图片实际尺寸。
  get actualMediaRect() {
    const { x, y, width, height } = this.graphic.getClientRect()

    return {
      x: roundTo(x),
      x1: roundTo(x + width / 2),
      x2: roundTo(x + width),
      y: roundTo(y),
      y1: roundTo(y + height / 2),
      y2: roundTo(y + height),
      width: roundTo(width),
      height: roundTo(height),
    }
  }

  // 开始截取。
  startCrop() {
    this.cacheBlurRadiusIfHas()
    this.preCropAttr = {
      cropX: this.cropX(),
      cropY: this.cropY(),
      cropWidth: this.cropWidth() || this.width(),
      cropHeight: this.cropHeight() || this.height(),
      imageWidth: this.imageWidth,
      imageHeight: this.imageHeight,
      imageX: this.imageX,
      imageY: this.imageY,
    }

    this.position({ x: this.x() + this.imageX, y: this.y() + this.imageY })
    super.width(this.imageWidth)
    super.height(this.imageHeight)
    this.imageX = 0
    this.imageY = 0
    this.cropX(0)
    this.cropY(0)
    this.cropWidth(this.imageWidth)
    this.cropHeight(this.imageHeight)
    this.graphic.size({
      width: this.imageWidth,
      height: this.imageHeight,
    })
    this.graphic.position({
      x: this.box.x(),
      y: this.box.y(),
    })
  }

  // 设置或获取截取。
  crop({
    x,
    y,
    width,
    height,
    imageX = 0,
    imageY = 0,
    imageWidth,
    imageHeight,
    cropX,
    cropY,
    cropWidth,
    cropHeight,
  }: Partial<IPos> &
    Partial<ISize> & {
      imageX?: number
      imageY?: number
      imageWidth?: number
      imageHeight?: number
      cropX: number
      cropY: number
      cropWidth: number
      cropHeight: number
    }) {
    this.imageX = imageX
    this.imageY = imageY

    if (!isNil(imageWidth)) {
      this.imageWidth = imageWidth
    }

    if (!isNil(imageHeight)) {
      this.imageHeight = imageHeight
    }

    if (!isNil(x)) {
      super.x(x)
    }

    if (!isNil(y)) {
      super.y(y)
    }

    if (!isNil(width)) {
      super.width(width)
    }

    if (!isNil(height)) {
      super.height(height)
    }

    this.cropX(cropX)
    this.cropY(cropY)
    this.cropWidth(cropWidth)
    this.cropHeight(cropHeight)

    this.graphic.size({
      width: cropWidth,
      height: cropHeight,
    })
    this.graphic.position({
      x: Math.max(0, imageX) + this.box.x(),
      y: Math.max(0, imageY) + this.box.y(),
    })

    this.preCropAttr = undefined

    this.restoreBlurRadius()
  }

  // 设置或获取截取 x。
  cropX(cropX?: number) {
    if (isNil(cropX)) {
      return this._cropX
    }

    this._cropX = cropX
    this.graphic.cropX(cropX * this.imgScale)

    return cropX
  }

  // 设置或获取截取 y。
  cropY(cropY?: number) {
    if (isNil(cropY)) {
      return this._cropY
    }

    this._cropY = cropY
    this.graphic.cropY(cropY * this.imgScale)

    return cropY
  }

  // 设置或获取截取宽度。
  cropWidth(cropWidth?: number) {
    if (isNil(cropWidth)) {
      return this._cropWidth
    }

    this._cropWidth = cropWidth
    this.graphic.cropWidth(cropWidth * this.imgScale)

    return cropWidth
  }

  // 设置或获取截取高度。
  cropHeight(cropHeight?: number) {
    if (isNil(cropHeight)) {
      return this._cropHeight
    }

    this._cropHeight = cropHeight
    this.graphic.cropHeight(cropHeight * this.imgScale)

    return cropHeight
  }

  // 重写父类的 x 方法。
  x(x?: number) {
    if (isNil(x)) {
      return super.x()
    }

    x = super.x(x)

    this.graphic.x(this.box.x() + Math.max(0, this.imageX))

    return x
  }

  // 重写父类的 y 方法。
  y(y?: number) {
    if (isNil(y)) {
      return super.y()
    }

    y = super.y(y)

    this.graphic.y(this.box.y() + Math.max(0, this.imageY))

    return y
  }

  // 重写父类的 width 方法。
  width(width?: number) {
    if (isNil(width)) {
      return super.width()
    }

    if (!this.isCropping) {
      this.cacheBlurRadiusIfHas()
    }

    const adaptSizeByImgRatio = () => {
      const { ratio } = this.graphic
      if (isNil(ratio)) {
        super.width(width)
      } else {
        super.width(width)
        this.graphic.width(this.box.width())
        const newH = roundTo1(width! / ratio)
        super.height(newH)
        this.graphic.height(this.box.height())
      }
    }

    if (this.getOperateAttrs().croppable) {
      if (this.isCropping) {
        adaptSizeByImgRatio()
        this.imageWidth = width
      } else {
        if (this.imageWidth === 0) {
          this.imageWidth = width
        }

        const imgRatio = this.getImgRatio()
        if (isNil(imgRatio)) {
          this.graphic.width(width)
        } else {
          this.syncImgAttr(width / this.width())
        }

        const imgBoxRatio = this.getImgBoxRatio()
        width = super.width(width)
        if (!isNil(imgBoxRatio)) {
          super.height(roundTo1(width / imgBoxRatio))
        }
      }
    } else {
      adaptSizeByImgRatio()
      const { width, height } = this.size()
      this._cropWidth = width
      this._cropHeight = height
      this.imageWidth = width
      this.imageHeight = height
    }

    if (!this.isCropping) {
      this.restoreBlurRadius()
    }

    return width
  }

  // 重写父类的 height 方法。
  height(height?: number) {
    if (isNil(height)) {
      return super.height()
    }

    if (!this.isCropping) {
      this.cacheBlurRadiusIfHas()
    }

    const adaptSizeByImgRatio = () => {
      const { ratio } = this.graphic
      if (isNil(ratio)) {
        super.height(height)
      } else {
        super.height(height)
        this.graphic.height(this.box.height())
        const newW = roundTo1(height! * ratio)
        super.width(newW)
        this.graphic.width(this.box.width())
      }
    }

    if (this.getOperateAttrs().croppable) {
      if (this.isCropping) {
        adaptSizeByImgRatio()
        this.imageHeight = height
      } else {
        if (this.imageHeight === 0) {
          this.imageHeight = height
        }

        const imgRatio = this.getImgRatio()
        if (isNil(imgRatio)) {
          this.graphic.height(height)
        } else {
          this.syncImgAttr(height / this.height())
        }

        const imgBoxRatio = this.getImgBoxRatio()
        height = super.height(height)
        if (!isNil(imgBoxRatio)) {
          super.width(roundTo1(height * imgBoxRatio))
        }
      }
    } else {
      adaptSizeByImgRatio()
      const { width, height } = this.size()
      this._cropWidth = width
      this._cropHeight = height
      this.imageWidth = width
      this.imageHeight = height
    }

    if (!this.isCropping) {
      this.restoreBlurRadius()
    }

    return height
  }

  // 获取图片框比例。
  getImgBoxRatio() {
    const { width, height } = this.size()
    if (width && height) {
      return width / height
    }
  }

  // 获取图片比例。
  getImgRatio() {
    const { width, height } = this.graphic.size()
    if (width && height) {
      return width / height
    }
  }

  // 同步图片属性。
  syncImgAttr(scale: number) {
    this.imageX *= scale
    this.imageY *= scale
    const ratio = this.graphic.width() / this.graphic.height()
    const mediaWidth = this.graphic.width() * scale
    const mediaHeight = mediaWidth / ratio
    this.imageWidth *= scale
    this.imageHeight *= scale
    this._cropX = this._cropX * scale
    this._cropY = this._cropY * scale
    this._cropWidth = this._cropWidth * scale
    this._cropHeight = this._cropHeight * scale

    this.graphic.size({ width: mediaWidth, height: mediaHeight })
    const { x, y } = this.box.position()
    this.graphic.position({
      x: Math.max(0, this.imageX) + x,
      y: Math.max(0, this.imageY) + y,
    })
  }

  // 是否正在截取。
  get isCropping() {
    return this === this.graphicEditor?.croppingImage
  }

  // 图片缩放比。
  get imgScale() {
    return this.graphic.imageBitmap!.width / this.imageWidth
  }

  // 重写父类的 url 方法。
  url(config?: Parameters<GraphicMedia<KonvaImage>['url']>[0]) {
    if (isNil(config)) {
      return super.url()
    }

    const originalOnLoadMedia = config?.onLoadMedia
    const originalOnDrawComplete = config?.onDrawComplete

    if (!this.isCropping) {
      this.cacheBlurRadiusIfHas()
    }

    return super.url({
      ...config,
      onLoadMedia: (image) => {
        if (this.getOperateAttrs().croppable) {
          const width = this.width()
          const height = this.height()
          const { ratio } = this.graphic

          let imageWidth = width
          let imageHeight = imageWidth / ratio!
          if (imageHeight < height) {
            imageHeight = height
            imageWidth = imageHeight * ratio!
          }

          const imageX = (width - imageWidth) / 2
          const imageY = (height - imageHeight) / 2

          this.crop({
            imageX,
            imageY,
            imageWidth,
            imageHeight,
            cropX: Math.abs(Math.min(0, imageX)),
            cropY: Math.abs(Math.min(0, imageY)),
            cropWidth: width,
            cropHeight: height,
          })
        }

        originalOnLoadMedia?.(image)
      },
      onDrawComplete: () => {
        if (!this.isCropping) {
          this.restoreBlurRadius()
        }

        originalOnDrawComplete?.()
      },
    })
  }

  // 设置或获取圆角。
  cornerRadius(cornerRadius?: number[]) {
    if (isNil(cornerRadius)) {
      return formatCornerRadiusToArray(this.graphic.cornerRadius())
    }

    if (!isEqual(cornerRadius, this.graphic.cornerRadius())) {
      if (!this.isCropping) {
        this.cacheBlurRadiusIfHas()
      }

      this.graphic.cornerRadius([...cornerRadius])

      if (!this.isCropping) {
        this.restoreBlurRadius()
      }
    }

    return cornerRadius
  }

  // 设置或获取模糊。
  blurRadius(blurRadius?: number) {
    if (isNil(blurRadius)) {
      return this.graphic.blurRadius()
    }

    if (this.firstDrawIsComplete) {
      const filters = this.graphic.filters() ?? []

      const isCached = this.graphic.isCached()
      if (blurRadius > 0) {
        if (!isCached) {
          this.graphic.cache()
        }

        if (!filters.includes(Blur)) {
          this.graphic.filters([...filters, Blur])
        }
      } else {
        this.graphic.clearCache()

        this.graphic.filters(filters.filter((filter) => filter !== Blur))
      }
    }

    this.graphic.blurRadius(blurRadius)

    return blurRadius
  }

  cacheBlurRadiusIfHas() {
    // 图片首次绘制已经完成。
    // 没有设置缓存过的模糊半径。
    // 当前图片设置了模糊半径。
    // 已上三种情况下才需要缓存模糊半径。
    if (
      this.firstDrawIsComplete &&
      (isNil(this.cachedBlurRadius) || this.blurRadius())
    ) {
      this.cachedBlurRadius = this.blurRadius()
      this.blurRadius(0)
    }
  }

  // 图形轮廓实际矩形框，用来表达实际展示的最大轮廓。
  get outlineActualRectBox() {
    return this.actualRectBox
  }

  // 矩形属性。
  get attrs() {
    return {
      ...super.attrs,
      cropX: roundTo1(this.preCropAttr?.cropX ?? this.cropX()),
      cropY: roundTo1(this.preCropAttr?.cropY ?? this.cropY()),
      cropWidth: roundTo1(this.preCropAttr?.cropWidth ?? this.cropWidth()),
      cropHeight: roundTo1(this.preCropAttr?.cropHeight ?? this.cropHeight()),
      imageWidth: roundTo1(this.preCropAttr?.imageWidth ?? this.imageWidth),
      imageHeight: roundTo1(this.preCropAttr?.imageHeight ?? this.imageHeight),
      imageX: roundTo1(this.preCropAttr?.imageX ?? this.imageX),
      imageY: roundTo1(this.preCropAttr?.imageY ?? this.imageY),
      cornerRadius: this.cornerRadius(),
      blurRadius: this.cachedBlurRadius || this.blurRadius(),
    }
  }

  getOperateAttrs() {
    return {
      ...super.getOperateAttrs(),
      croppable: true,
      hasRadius: true,
    }
  }
}
