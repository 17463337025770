import { AnyObject, IResponseData } from '@lanyan/type'
import to from 'await-to-js'
import axios, {
  Axios,
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  CreateAxiosDefaults,
} from 'axios'
import { omitBy } from 'lodash-es'
import { stringify } from 'qs'

export class Request {
  private _axiosInstance: AxiosInstance | null = null

  constructor(options: { baseUrl: string; skipNilsInParams?: any[] }) {
    const axiosOptions = {
      baseURL: options.baseUrl,
      withCredentials: true,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      timeout: 2 * 60 * 1000,
    } as CreateAxiosDefaults

    axiosOptions.paramsSerializer = (params: AnyObject) => {
      params = omitBy(params, (value) => {
        return options.skipNilsInParams?.includes(value)
      })

      return stringify(params, {
        arrayFormat: 'brackets',
      })
    }

    this._axiosInstance = axios.create(axiosOptions)
  }

  _request<T = any>(config: AxiosRequestConfig) {
    return this._axiosInstance!.request<T>(config) as unknown as Promise<
      IResponseData<T>
    >
  }

  post<T = any>(url: string, config: AxiosRequestConfig = {}) {
    return to<IResponseData<T>, AxiosError<IResponseData<T>>>(
      this._request<T>({ ...config, method: 'POST', url }),
    )
  }

  get<T = any>(url: string, config: AxiosRequestConfig = {}) {
    return to<IResponseData<T>, AxiosError<IResponseData<T>>>(
      this._request<T>({ ...config, method: 'GET', url }),
    )
  }

  head<T = any>(url: string, config: AxiosRequestConfig = {}) {
    return to<IResponseData<T>, AxiosError<IResponseData<T>>>(
      this._request<T>({ ...config, method: 'HEAD', url }),
    )
  }

  put<T = any>(url: string, config: AxiosRequestConfig = {}) {
    return to<IResponseData<T>, AxiosError<IResponseData<T>>>(
      this._request<T>({ ...config, method: 'PUT', url }),
    )
  }

  patch<T = any>(url: string, config: AxiosRequestConfig = {}) {
    return to<IResponseData<T>, AxiosError<IResponseData<T>>>(
      this._request<T>({ ...config, method: 'PATCH', url }),
    )
  }

  delete<T = any>(url: string, config: AxiosRequestConfig = {}) {
    return to<IResponseData<T>, AxiosError<IResponseData<T>>>(
      this._request<T>({ ...config, method: 'DELETE', url }),
    )
  }

  // 添加请求拦截器。
  addRequestInterceptor(
    ...params: Parameters<Axios['interceptors']['request']['use']>
  ) {
    this._axiosInstance?.interceptors.request.use(...params)
  }

  // 添加 x 响应拦截器
  addResponseInterceptor(
    ...params: Parameters<Axios['interceptors']['response']['use']>
  ) {
    this._axiosInstance?.interceptors.response.use(...params)
  }
}
