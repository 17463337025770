import { pick } from 'lodash-es'
import resolveConfig from 'tailwindcss/resolveConfig'

// 最大尺寸。
const FULL_SIZE = 9999

// 尺寸范围。
const SIZE = 500

// 数字列表转带单位的和负值的尺寸配置。
const genSizeConfig = (sizeConfig: number[]) => {
  return sizeConfig.reduce((sum, v) => {
    sum[v] = `${v}px`

    sum[`-${v}`] = `-${v}px`

    return sum
  }, {})
}

// 数字列表，范围是 0-500，999
const numbers = [...new Array(SIZE + 1).fill(null).map((_, i) => i), FULL_SIZE]

// 数字列表转对象形式，key 和 value 相同
const objNumber = numbers.reduce((sum, v) => {
  sum[v] = v

  return sum
}, {})

// 带有 px 单位的尺寸列表
const sizeConfig = genSizeConfig(numbers)

// 基础颜色。
// key 和 value 强相关
export const baseColors = {
  gray: '#f0f0f0',
  'gray-5': '#f5f5f5',
  'gray-6': '#f7f7f7',
  'gray-8': '#ebebeb',
  'gray-10': '#d9d9d9',
  'gray-20': 'rgba(0, 0, 0, 0.15)',
  'gray-30': 'rgba(0, 0, 0, 0.25)',
  'gray-40': 'rgba(0, 0, 0, 0.45)',
  'gray-50': 'rgba(0, 0, 0, 0.65)',
  'gray-60': 'rgba(0, 0, 0, 0.85)',
  white: '#fff',
  black: '#000',
  blue: '#1677ff',
  purple: '#6a4fff',
  'purple-1': '#f8f8ff',
  'purple-2': '#f3f3ff',
  'purple-3': '#ededfe',
  green: '#52c41a',
  red: '#ff4d4f',
  yellow: '#faad14',
}

// 业务颜色。
// key 和 value 不相关
export const businessColors = {
  black: baseColors.black,
  white: baseColors.white,
  primary: baseColors.purple,
  success: baseColors.green,
  error: baseColors.red,
  warning: baseColors.yellow,
  transparent: 'transparent',
}

export const backgroundColor = {
  ...baseColors,
  ...businessColors,
  light: baseColors['gray'],
  normal: baseColors['gray-10'],
}

export const divideColor = {
  ...baseColors,
  ...businessColors,
  light: baseColors['gray'],
  normal: baseColors['gray-10'],
}

export const borderColor = {
  ...baseColors,
  ...businessColors,
  light: baseColors['gray'],
  normal: baseColors['gray-10'],
  dark: baseColors['gray-30'],
}

export const textColor = {
  ...baseColors,
  ...businessColors,
  disabled: baseColors['gray-30'],
  placeholder: baseColors['gray-30'],
  lighter: baseColors['gray-30'],
  light: baseColors['gray-40'],
  normal: baseColors['gray-50'],
  dark: baseColors['gray-60'],
}

const tailwindConfig = resolveConfig({
  content: [
    './src/**/*.{vue,tsx,ts,js,jsx}',
    '../../packages/component/src/**/*.{vue,tsx,ts,js,jsx}',
  ],

  // theme 中的属性会覆盖 tailwind 的内置属性
  theme: {
    spacing: pick(sizeConfig, [0, 1, 2, 3, 4, 6, 8, 12, 16, 20, 24, 32, 48]),
    fontSize: pick(
      sizeConfig,
      [10, 11, 12, 13, 14, 16, 18, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60],
    ),
    colors: { ...baseColors, ...businessColors },
    borderRadius: {
      ...pick(sizeConfig, [4, 6, 8, 20]),
      none: sizeConfig[0],
      full: sizeConfig[FULL_SIZE],
    },
    borderWidth: {
      ...pick(sizeConfig, [1, 2, 3, 4]),
      none: sizeConfig[0],
    },
    backgroundColor,
    divideColor,
    borderColor,
    textColor,
    boxShadow: {
      shadow1: 'rgb(10 149 255 / 10%) 0 0 0 4px, rgb(0 0 0 / 4%) 0 0 0 1px',
      shadow2: 'rgb(10 149 255 / 10%) 0 0 0 4px, rgb(10 149 255) 0 0 0 1px',
      shadow3:
        '0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
      shadow4: 'rgba(0, 0, 0, 0.25) 0px 1px 2px',
      shadow5: 'rgb(106 79 255 / 10%) 0 0 0 2px, rgb(0 0 0 / 4%) 0 0 0 1px',
      shadow6: 'rgb(106 79 255 / 10%) 0 0 0 2px, rgb(106 79 255) 0 0 0 1px',
      none: 'none',
    },

    // extend 中的属性不会覆盖 tailwind 的内置属性
    extend: {
      lineHeight: sizeConfig,
      position: numbers,
      flex: objNumber,
      zIndex: objNumber,
      width: sizeConfig,
      minWidth: sizeConfig,
      maxWidth: sizeConfig,
      height: sizeConfig,
      minHeight: sizeConfig,
      maxHeight: sizeConfig,
      transitionProperty: {
        size: 'height, width',
        height: 'height',
        width: 'width',
        spacing: 'margin, padding',
        translate: 'translate',
        transform: 'transform',
      },
    },
  },
  corePlugins: {
    preflight: false,
  },
})

export default tailwindConfig
